import React, { useEffect, useState, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Loader from "../components/Loader";
import { FaDownload, FaTimes, FaPlay } from "react-icons/fa";

const ImageComponent = React.lazy(() => import("../components/Image"));
const VideoComponent = React.lazy(() => import("../components/Video"));

function Gallery() {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [selectedMedia, setSelectedMedia] = useState(null);
	const mediaFiles = require.context(
		"../../public/assets/images",
		false,
		/\.(png|jpe?g|svg|mp4|webm)$/
	);
	const [selectedFilter, setSelectedFilter] = useState("all");
	const media = mediaFiles.keys();
	const [filteredItems, setFilteredItems] = useState(media);

	useEffect(() => {
		setFilteredItems(
			selectedFilter === "all"
				? media
				: media.filter((item) => {
						return item.slice(2).split("_")[0] === selectedFilter;
				  })
		);
		setLoading(false);
	}, [selectedFilter]);

	const openModal = (item) => {
		setSelectedMedia(item);
	};

	const closeModal = () => {
		setSelectedMedia(null);
	};

	const handleBackdropClick = () => {
		closeModal();
	};

	const handleContentClick = (event) => {
		event.stopPropagation();
	};

	const changeFilter = (name) => {
		setSelectedFilter(name);
	};

	if (loading) {
		return <Loader />;
	}

	return (
		<>
			<Navbar />
			<div className="row" data-aos="fade-up">
				<div className="col-lg-12 flex justify-center">
					<ul id="portfolio-flters" className="flex">
						<li
							onClick={() => changeFilter("all")}
							className={selectedFilter === "all" ? "filter-active" : ""}
						>
							All
						</li>
						<li
							className={
								selectedFilter === "melkainunush" ? "filter-active" : ""
							}
							onClick={() => changeFilter("melkainunush")}
						>
							M & N
						</li>
						<li
							className={selectedFilter === "melka" ? "filter-active" : ""}
							onClick={() => changeFilter("melka")}
						>
							Melka
						</li>
						<li
							className={selectedFilter === "nunush" ? "filter-active" : ""}
							onClick={() => changeFilter("nunush")}
						>
							Nunush
						</li>
					</ul>
				</div>
			</div>
			<div className="mx-4 flex flex-col items-center min-h-screen">
				<div className="xs:grid-cols-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 items-center w-full">
					{filteredItems.map((item, index) => {
						const isVideo = item.match(/\.(mp4|webm)$/);
						const mediaPath = `/assets/images/${item.slice(2)}`;
						return (
							<div key={index} className="relative group">
								<Suspense fallback={<div className="w-full h-full" />}>
									{isVideo ? (
										<VideoComponent
											mediaPath={mediaPath}
											openModal={() => openModal(item)}
										/>
									) : (
										<ImageComponent
											mediaPath={mediaPath}
											openModal={() => openModal(item)}
										/>
									)}
								</Suspense>
							</div>
						);
					})}
				</div>
			</div>
			{selectedMedia && (
				<div
					className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75"
					onClick={handleBackdropClick}
				>
					<div className="max-w-lg mx-auto" onClick={handleContentClick}>
						{selectedMedia.match(/\.(mp4|webm)$/) ? (
							<video
								src={`/assets/images/${selectedMedia.slice(2)}`}
								controls
								className="object-contain max-h-full max-w-full"
							/>
						) : (
							<img
								src={`/assets/images/${selectedMedia.slice(2)}`}
								className="object-contain max-h-full max-w-full"
								alt={selectedMedia}
							/>
						)}
						<a
							className="absolute top-6 right-16 text-white text-md"
							href={`/assets/images/${selectedMedia.slice(2)}`}
							download={`${selectedMedia.split("/")[1]}`}
						>
							<FaDownload />
						</a>
						<button
							className="absolute top-5 right-4 text-white text-2xl"
							onClick={closeModal}
						>
							<FaTimes />
						</button>
					</div>
				</div>
			)}
		</>
	);
}

export default Gallery;
