import React from "react";
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function Home() {
	const navigate = useNavigate();
	return (
		<>
			<Navbar />
			<div className="container">
				<div className="box">
					<div className="title">
						<span className="block"></span>
						<h1 className="md:text-[56px] text-[43px]">
							Happy 6 Months
							<span></span>
						</h1>
					</div>

					<div className="role">
						<div className="block"></div>
						<p className="md:text-[32px] text-[28px]">I love you</p>
					</div>
					<button
						onClick={() => navigate("/notes")}
						className="btn bg-[#363434] mt-4 max-w-36 text-lg text-white hover:bg-melisa-orange transition duration-300"
					>
						CLICK ME
					</button>
				</div>
			</div>
		</>
	);
}

export default Home;
