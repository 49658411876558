import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import Gallery from "./pages/Gallery";
import Notes from "./pages/Notes";
import NotFound from "./pages/NotFound";
import Home from "./pages/Home";

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<LoginPage />} />
				<Route path="/home" element={<Home />} />
				<Route path="/gallery" element={<Gallery />} />
				<Route path="/notes" element={<Notes />} />
				<Route path="*" element={<NotFound />} />
				<Route path="assets/*" element={<Navigate to="/" />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
