import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const firebaseConfig = {
	apiKey: "AIzaSyAy3ZjluRldDabou61o_c6F5YdpPgr31is",
	authDomain: "melisa-6d8ad.firebaseapp.com",
	databaseURL: "https://melisa-6d8ad.firebaseio.com",
	projectId: "melisa-6d8ad",
	storageBucket: "melisa-6d8ad.appspot.com",
};

if (!firebase.apps.length) {
	firebase.initializeApp(firebaseConfig);
}

const Navbar = () => {
	const navigate = useNavigate();

	useEffect(() => {
		const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
			if (!user) {
				navigate("/");
			} else {
				console.log(user._delegate);
			}
		});

		return () => unsubscribe();
	}, [navigate]);

	const handleLogout = () => {
		firebase.auth().signOut();
		navigate("/");
	};

	return (
		<div className="navbar bg-[#1a1a1a] px-5">
			<div className="flex-1">
				<button
					onClick={() => navigate("/home")}
					className="btn btn-ghost hidden md:block"
				>
					Home
				</button>
				<button
					onClick={() => navigate("/gallery")}
					className="btn btn-ghost hidden md:block"
				>
					Pictures
				</button>
				<button
					onClick={() => navigate("/notes")}
					className="btn btn-ghost hidden md:block"
				>
					Notes
				</button>
				{/* Mobile Dropdown */}
				<div className="dropdown md:hidden">
					<label tabIndex={0} className="btn btn-ghost lg:hidden">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
							className="w-6 h-6"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M4 6h16M4 12h16m-7 6h7"
							/>
						</svg>
					</label>
					<ul
						tabIndex={0}
						className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-[#242323] rounded-box w-52"
					>
						<li>
							<a onClick={() => navigate("/home")}>Home</a>
						</li>
						<li>
							<a onClick={() => navigate("/gallery")}>Pictures</a>
						</li>
						<li>
							<a onClick={() => navigate("/notes")}>Notes</a>
						</li>
					</ul>
				</div>
			</div>
			<div className="flex-none">
				<div className="dropdown dropdown-end">
					<div
						tabIndex={0}
						role="button"
						className="btn btn-ghost btn-circle avatar"
					>
						<div className="w-10 rounded-full">
							<img alt="melkainunush" src="/assets/images/melkainunush.png" />
						</div>
					</div>
					<ul
						tabIndex={0}
						className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-[#242323] rounded-box w-52"
					>
						<li>
							<a onClick={handleLogout}>Logout</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default Navbar;
