export default function Loader() {
	return (
		<div className="bg-[#1a1a1a]">
			<div className="honeycomb flex items-center justify-center h-screen">
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
	);
}
