import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Loader from "../components/Loader";

// Custom hook to fetch and process notes
const useNotes = () => {
	const [notes, setNotes] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchNotes = async () => {
			const context = require.context(
				"../../public/assets/notes",
				false,
				/\.txt$/
			);
			const keys = context.keys();

			const notesData = await Promise.all(
				keys.map(async (key) => {
					const response = await fetch(context(key));
					const content = await response.text();
					const title = key
						.replace("../../public/assets/notes/", "")
						.replace(".txt", "")
						.split("_")
						.join(" ")
						.slice(2);

					return { title, content };
				})
			);

			setNotes(notesData);
			setLoading(false);
		};

		fetchNotes();
	}, []);

	return { notes, loading };
};

const Notes = () => {
	const { notes, loading } = useNotes();
	const [selectedNote, setSelectedNote] = useState(null);

	const openNote = (note) => {
		setSelectedNote(note);
	};

	const closeNote = () => {
		setSelectedNote(null);
	};

	if (loading) {
		return <Loader />;
	}

	return (
		<>
			<Navbar />
			<div className="min-h-screen p-4 flex flex-col items-center bg-[#1a1a1a]">
				<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 w-full">
					{notes.map((note, index) => (
						<div
							key={index}
							className="bg-[#242323] text-white p-4 min-h-64 rounded-lg cursor-pointer shadow-lg hover:bg-[#363434] duration-300 transition-all"
							onClick={() => openNote(note)}
						>
							<h2 className="text-xl font-bold">{note.title}</h2>
							<p className="line-clamp-8 text-gray-300 whitespace-pre-wrap">
								{note.content}
							</p>
						</div>
					))}
				</div>

				{selectedNote && (
					<div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
						<div className="bg-[#242323] p-8 rounded-lg relative max-w-3xl mx-4 min-w-[50vw] h-[90vh] overflow-y-auto">
							<button
								className="absolute top-4 right-4 text-white text-2xl font-bold"
								onClick={closeNote}
							>
								&times;
							</button>
							<h2 className="text-2xl font-bold mb-4">{selectedNote.title}</h2>
							<p className="text-white whitespace-pre-wrap">
								{selectedNote.content}
							</p>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default Notes;
